<template>
  <div>
    <!-- Top Domain Details -->
    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="domain-info  mb-8">
          <!-- Date Picker -->
          <date-range-picker class="review-date" :fromDateValue="fromDate" :toDateValue="toDate" @inputFromDate="(newFromDate) => {fromDate = newFromDate}" @inputToDate="(newToDate) => {toDate = newToDate}"></date-range-picker>
          <!-- Update Dashboard -->
          <vs-button type="filled" @click="updateData()" class="small-btn bg-actionbutton">{{ LabelConstant.buttonLabelRunQuery }}</vs-button>
        </div>
      </div>
    </div>
    <!-- Card -->
    <div class="vx-row">
      <div class="vx-col xl:w-1/4  mb-8  md:w-1/2 w-full lg-cus-6">
        <single-card
          icon="BriefcaseIcon"
          smallTitle="Emails Sent"
          :totalValue="emailsSent.StatEndValue"
          :bottomText="emailsSent.StatStartValue+' previous period'"
          :upDownValue="emailsSent.StatChangePercent"      
          :startValue="emailsSent.StatStartValue"
          bgcolor="bg-first-gradient"
          maintextcolor="text-first"
          bgimg="beg.png"
        >
        </single-card> 
      </div>
      <div class="vx-col xl:w-1/4  mb-8  md:w-1/2 w-full lg-cus-6">
        <single-card
          icon="MailIcon"
          smallTitle="Emails Opened"
          :totalValue="emailsOpened.StatEndValue"
          :bottomText="emailsOpened.StatStartValue+' previous period'"
          :upDownValue="emailsOpened.StatChangePercent"
          :startValue="emailsOpened.StatStartValue"
          bgcolor="bg-second-gradient"
          maintextcolor="text-second"
          bgimg="mail.png"
        >
        </single-card>
      </div>
      <div class="vx-col xl:w-1/4  mb-8  md:w-1/2 w-full lg-cus-6">
        <single-card
          icon="MailIcon"
          smallTitle="Email Templates"
          :totalValue="emailTemplates.StatEndValue"
          :bottomText="emailTemplates.StatStartValue+' previous period'"
          :upDownValue="emailTemplates.StatChangePercent"
          :startValue="emailTemplates.StatStartValue" 
          bgcolor="bg-third-gradient"          
          maintextcolor="text-third"   
          bgimg="mail.png"
        >
        </single-card>
      </div>
      <div class="vx-col xl:w-1/4 mb-8 md:w-1/2 w-full lg-cus-6">
        <single-card
          icon="ClockIcon"
          smallTitle="Avg Time to Open"
          :totalValue="avgTimeToOpen.StatEndValue"
          :bottomText="avgTimeToOpen.StatStartValue+' previous period'"
          :upDownValue="avgTimeToOpen.StatChangePercent"
          :startValue="avgTimeToOpen.StatStartValue"
          bgcolor="bg-fourth-gradient"          
          maintextcolor="text-fourth"
          bgimg="clock.png"
          :positiveNegative="true"
        >
        </single-card>
      </div>
    </div>
    <!-- Most Opened Emails -->
    <div class="vx-row mb-8">
        <div class="vx-col xl:w-4/12 col-cus-12 mb-8 w-full">
            <vx-card class="main-list h-full">
            <div class="card-title">
                <h2>Most Opened Emails</h2>
                <!-- <vs-button @click="popupActive=true" color="gray" type="flat" icon-pack="feather" icon="icon-help-circle" class="p-0 help-btn" ></vs-button> -->
            </div>
            <ul class="latest-list enails-time-list">
                <li v-for="(mostOpenedEmail, index) in mostOpenedEmails" :key="index" v-if="mostOpenedEmails && mostOpenedEmails.length > 0 && index < limitedMostOpenedEmails">
                  <div class="left-emails"><a @click="isEmailDetailsData=true, getEmailDetailByEmailId(mostOpenedEmail.Email_Id)">{{mostOpenedEmail.Email_To}}</a><span class="date">{{mostOpenedEmail.Subject}}</span></div>
                  <div class="email-times"><span>{{mostOpenedEmail.Times_Opened}}</span><span class="date">times</span></div>                                    
                  <a @click="isEmailDetailsData=true, getEmailDetailByEmailId(mostOpenedEmail.Email_Id)" class="go-link text-actionbutton">
                    <feather-icon icon="EyeIcon"></feather-icon></a>
                </li>
                <p v-if="mostOpenedEmails.length < 1">No opened emails for this date range.</p>
            </ul>
            <vs-button class="small-btn bg-actionbutton" type="filled" icon-pack="feather" icon="icon-eye" v-if="mostOpenedEmails.length > 0 && limitedMostOpenedEmails == 5" @click="showMoreEmails()">Show More</vs-button>
            <vs-button class="small-btn bg-actionbutton" type="filled" icon-pack="feather" icon="icon-eye"  v-if="mostOpenedEmails.length > 0 && limitedMostOpenedEmails > 5" @click="showMoreEmails()">Show Less</vs-button>
            </vx-card>
        </div>
        <div class="vx-col xl:w-8/12 col-cus-12 mb-8 w-full">
            <vx-card>
            <div class="card-title">
                <h2>Message Overview</h2>
                <!-- <vs-button @click="popupActive=true" color="gray" type="flat" icon-pack="feather" icon="icon-help-circle" class="p-0 help-btn" ></vs-button> -->
            </div>
            <vue-apex-charts type="line" height="400" :options="messageOverview.chartOptions" :series="messageOverview.series"></vue-apex-charts>
            </vx-card>
        </div>
        <div class="vx-col w-full">
            <vx-card>
              <div class="card-title">
                  <h2>Daily Average Time To Open in Minutes</h2>
                  <!-- <vs-button @click="popupActive=true" color="gray" type="flat" icon-pack="feather" icon="icon-help-circle" class="p-0 help-btn" ></vs-button> -->
              </div>
              <vue-apex-charts type="area" height="400" :options="dailyAverageTimeToOpenMinutes.chartOptions" :series="dailyAverageTimeToOpenMinutes.series"></vue-apex-charts>
              <!-- <one-xaxis-chart
                chartTitle="Daily Average Time To Open in Minutes"
                chartType="line"
                chartHeight="400"
                seriesNameOne="Avg Time To Open In Minutes"
                :seriesDataOne="this.dailyAverageTimeToOpenMinutes.series[0].data"
                :optionsXaxis="this.dailyAverageTimeToOpenMinutes.chartOptions.xaxis.categories"
              ></one-xaxis-chart> -->
            </vx-card>
        </div>
    </div>
    <!-- Help popup -->
    <help-modal :popupActive='popupActive' @close="closepopup" ></help-modal>    
    <!-- Email Details Popup -->      
    <vs-popup class="company-details-modal close-open" title="Email Details" :active.sync="emailDetailsPopupActive" v-if="isEmailDetailsData"> 
      <vs-button
          @click="closeViewEmailData()"
          color="secondary"
          type="filled"
          class="mb-8 float-right close-popup-btn"
        >{{ LabelConstant.buttonLabelClose }}</vs-button>
        <template>
            <div class="vx-row mb-3">
                <div class="vx-col w-full">                   
                    <ul class="account-summary mt-4 mb-2 md:mt-0">
                        <li class="pt-0 w-full md:w-1/2 mb-4 md:mb-0">
                            <h4><b>Date Sent :</b> {{emailDetailsData.Date_Created}}</h4>                            
                        </li>
                        <li class="pt-0 w-full md:w-1/2 mb-4 md:mb-0">
                            <h4><b>Email To :</b> {{emailDetailsData.Email_To}}</h4>
                        </li>
                        <li class="w-full md:w-1/2 mb-4 md:mb-0">
                            <h4><b>Subject :</b> {{emailDetailsData.Subject}}</h4>
                        </li>
                        <li class="w-full md:w-1/2 mb-4 md:mb-0">
                            <h4><b>Template :</b> {{emailDetailsData.Template_Name}}</h4>
                        </li>
                        <li class="w-full md:w-1/2">
                            <h4><b>Time To Open First :</b> {{emailDetailsData.Humanized_Time_To_Open}}</h4>
                        </li>
                    </ul>
                </div>
                <div class="vx-col w-full option-section border-0 mt-0">                    
                    <div class="vx-row">
                        <div class="vx-col w-full">
                            <vs-table :data="emailDetailsData.Activity_Stream">
                              <template slot="thead">
                                <vs-th>Date</vs-th>
                                <vs-th>Activity</vs-th>
                              </template>
                            <template slot-scope="{data}">
                            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                                <vs-td :data="data[indextr].Date_Formatted">
                                  {{ data[indextr].Date_Formatted }}
                                </vs-td>
                                <vs-td :data="data[indextr].Activity">
                                  {{ data[indextr].Activity }}
                                </vs-td>
                            </vs-tr>
                            </template>
                        </vs-table>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <div class="float-right mt-6 mb-8">            
            <vs-button  @click="closeViewEmailData()" color="grey" type="border" class="ml-4 grey-btn" >{{ LabelConstant.buttonLabelClose }}</vs-button>
        </div>            
    </vs-popup>
  </div>  
</template>

<script>
import VxCard from "@/components/vx-card/VxCard";

/* Filter */
import DateRangePicker from "../../components/filter/DateRangePicker";
import SingleCard from "../../components/SingleCard";
import HelpModal from "../../components/modal/HelpModal";

/* chart */
import VueApexCharts from "vue-apexcharts";
// import OneXaxisChart from '../../components/charts/OneXaxisChart.vue';


export default {
  components: {
    VxCard,    
    DateRangePicker,
    SingleCard,    
    HelpModal,
    VueApexCharts,
    // OneXaxisChart
  },
  data() {
     let uid = this.$route.params.id;
    let VVtoken = localStorage.getItem("VVtoken");
    let token = JSON.parse(VVtoken);
    return {
      //Help popup
      popupActive: false,

      //From Date
      fromDate: null,
      //To Date
      toDate: null,

      //Emails Sent
      emailsSent: {
        StatName: null,
				StatChangePercent: 0,
				StatEndValue: 0,
				StatStartValue: 0,
      },

      //Emails Opened
      emailsOpened: {
        StatName: null,
				StatChangePercent: 0,
				StatEndValue: 0,
				StatStartValue: 0,
      },

      //Email Templates
      emailTemplates: {
        StatName: null,
				StatChangePercent: 0,
				StatEndValue: 0,
				StatStartValue: 0,
      },

      //Avg Time to Open
      avgTimeToOpen: {
        StatName: null,
				StatChangePercent: 0,
				StatEndValue: 0,
				StatStartValue: 0,
      },

      //Most Opened Emails
      mostOpenedEmails: [],
      //Initial Most Opened Emails limited to 5 records.
			limitedMostOpenedEmails: 5,

      //Most Opened Emails details Popup
      emailDetailsPopupActive:false,
      emailDetailsData: {},
      isEmailDetailsData: false,
      
      //Message Overview
      messageOverview: {
        series: [
          {
            name: "Messages Sent",
            type: "column",
            data: []
          },
          {
            name: "Messages Opened",
            type: "area",
            data: []
          }
        ],
        chartOptions: {
          colors: [token[uid].themeDetail.tempChartColor1, token[uid].themeDetail.tempChartColor2],
          stroke: {
            width: [0, 3],
            curve: "smooth"
          },
          plotOptions: {
            bar: {
              columnWidth: "30%",
              endingShape: 'rounded',
            }
          },
          fill: {
            type: "solid",
            opacity: [1, 0.2, 1],
            gradient: {
              type: "vertical",
              shadeIntensity: 0,
              opacityFrom: 1,
              opacityTo: 0.2,
              stops: [0, 90, 100]
            }
          },
          markers: {
            size: 0
          },
          xaxis: {
            type: "datetime",
            categories: [],
            labels: {
              hideOverlappingLabels: true,
              showDuplicates: false,
              format: 'M/d',
            }
          },
          legend: {
            position: 'bottom',
            fontSize: '12px',
            fontFamily: '"Montserrat", Helvetica, Arial, sans-serif',            
            itemMargin: {
                horizontal: 5,
                vertical: 10
            },
            onItemClick: {
                toggleDataSeries: true
            },
            markers: {
              width: 12,
              height: 12,
              radius: 2,
            },
          },
          grid: {
            show: true,
            borderColor: "#f2f2f2",
            strokeDashArray: 3,
            position: "back",
            xaxis: {
              lines: {
                show: false
              }
            }
          },
          yaxis: [
            {
              axisTicks: {
                  show: true,
              },
              axisBorder: {
                  show: true,
                  color:token[uid].themeDetail.tempChartColor1
              },
              labels: {
                  style: {
                    color:token[uid].themeDetail.tempChartColor1
                  },
                  formatter: function (val) {
                    return parseInt(val).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                  },
              },
              title: {
                  text: "Messages Sent",
                  style: {
                  color:token[uid].themeDetail.tempChartColor1
                  }
              },
              tooltip: {
                  enabled: true
              }
            },
            {
              seriesName: 'Messages Opened',
              opposite: true,
              axisTicks: {
                  show: true,
              },
              axisBorder: {
                  show: true,
                  color: token[uid].themeDetail.tempChartColor2
              },
              labels: {
                  style: {
                    color: token[uid].themeDetail.tempChartColor2
                  },
                  formatter: function (val) {
                    return parseInt(val).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                  },
              },
              title: {
                  text: "Messages Opened",
                  style: {
                    color: token[uid].themeDetail.tempChartColor2
                  }
              },
            }
          ],
          tooltip: 
          {
              shared: true,
              intersect: false,
              x: {
                show: true,
                format: 'M/d',
              },
              y: {
                formatter: function (val) {
                    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                },
              },
          }
        }
      },

      //Daily Average Time To Open in Minutes
       dailyAverageTimeToOpenMinutes: {
        series: [
          {
            name: "Avg Time To Open in Minutes",
            data: []
          },         
        ],
        chartOptions: {
          colors: [token[uid].themeDetail.tempChartColor1],
          stroke: {
            width: [3],
            curve: "smooth"
          },
          fill: {
            type:'solid',
            opacity: [0.2, 1],
            gradient: {
              type: "vertical",
              shadeIntensity: 0,
              opacityFrom: 1,
              opacityTo: 0.2,
              stops: [0, 90, 100]
            }
          },
          xaxis: {
            type: "datetime",
            categories: [],
            labels: {
              hideOverlappingLabels: true,
              showDuplicates: false,
              format: 'M/d',
            }
          },
          legend: {
            position: "bottom",
            fontSize: "12px",
            fontFamily: '"Montserrat", Helvetica, Arial, sans-serif',
            itemMargin: {
              horizontal: 10,
              vertical: 10
            },
            onItemClick: {
              toggleDataSeries: true
            },
            markers: {
              width: 12,
              height: 12,
              radius: 2
            }
          },
          grid: {
            show: true,
            borderColor: "#f2f2f2",
            strokeDashArray: 3,
            position: "back",
            xaxis: {
              lines: {
                show: false
              }
            }
          },
          yaxis: [
            {
              axisTicks: {
                  show: true,
              },
              axisBorder: {
                  show: true,
                  color: token[uid].themeDetail.tempChartColor1
              },
              labels: {
                  style: {
                    color: token[uid].themeDetail.tempChartColor1,
                  },
                  formatter: function (val) {
                    return parseInt(val).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                  },
              },
              title: {
                  text: "Avg Time To Open in Minutes",
                  style: {
                  color: token[uid].themeDetail.tempChartColor1,
                  }
              },
              tooltip: {
                  enabled: true
              }
            },
          ],
          tooltip: {
            shared: true,
            intersect: false,
            x: {
              show: true,
              format: 'M/d',
            },
            y: {
              formatter: function (val) {
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              },
            },
          },
          dataLabels: {
            enabled: false
          }
        }
      }
    };
  },
  created(){
    this.setFromDate()
    this.setToDate()

    this.getEmailAvgTimeOpenStat()
    this.getEmailSentWithTemplateCount()
    this.getEmailOpenCount()
    this.getEmailSentCount()
    this.getMostOpenedEmails()
    this.getMessagesSentMessagesOpenedDay()
    this.getMessageAvgOpenTimeOpenedDay()
  },
  methods:{
    /* Close Popup */
    closepopup(){
      this.popupActive = false
    },
    //On clicking Run Query button will update all data
    updateData(){
      this.getEmailAvgTimeOpenStat()
      this.getEmailSentWithTemplateCount()
      this.getEmailOpenCount()
      this.getEmailSentCount()
      this.getMostOpenedEmails()
      this.getMessagesSentMessagesOpenedDay()
      this.getMessageAvgOpenTimeOpenedDay()
    },
    //For most opened emails 'show more' and 'show less' toggle
		showMoreEmails(){
			if(this.limitedMostOpenedEmails == 5){
				this.limitedMostOpenedEmails = this.mostOpenedEmails.length
			}else{
				this.limitedMostOpenedEmails = 5
			}
    },
    //Emails Sent
    getEmailSentCount(){
      let getEmailSentCountParams = {
        Date_End: this.toDate,
        Date_Start: this.fromDate
      }
      this.$vs.loading()
      this.axios.post("/ws/EmailTrackDashboard/GetEmailSentCount", getEmailSentCountParams).then(response => {
        let data = response.data;
        
        this.emailsSent.StatName = data.StatName;
				this.emailsSent.StatChangePercent = data.StatChangePercent;
				this.emailsSent.StatEndValue = this.numberFormatter(data.StatEndValue);
				this.emailsSent.StatStartValue = this.numberFormatter(data.StatStartValue);
				this.$vs.loading.close()
      }).catch(e => {
        this.$vs.loading.close()
        this.showError(e);
      });
    },
    //Emails Opened
    getEmailOpenCount(){
      let getEmailOpenCountParams = {
        Date_End: this.toDate,
        Date_Start: this.fromDate
      }
      this.$vs.loading()
      this.axios.post("/ws/EmailTrackDashboard/GetEmailOpenCount", getEmailOpenCountParams).then(response => {
        let data = response.data;
        
        this.emailsOpened.StatName = data.StatName;
				this.emailsOpened.StatChangePercent = data.StatChangePercent;
				this.emailsOpened.StatEndValue = this.numberFormatter(data.StatEndValue);
				this.emailsOpened.StatStartValue = this.numberFormatter(data.StatStartValue);
				this.$vs.loading.close()
      }).catch(e => {
        this.$vs.loading.close()
        this.showError(e);
      });
    },
    //Email Templates
    getEmailSentWithTemplateCount(){
      let getEmailSentWithTemplateCountParams = {
        Date_End: this.toDate,
        Date_Start: this.fromDate
      }
      this.$vs.loading()
      this.axios.post("/ws/EmailTrackDashboard/GetEmailSentWithTemplateCount", getEmailSentWithTemplateCountParams).then(response => {
        let data = response.data;
        
        this.emailTemplates.StatName = data.StatName;
				this.emailTemplates.StatChangePercent = data.StatChangePercent;
				this.emailTemplates.StatEndValue = this.numberFormatter(data.StatEndValue);
				this.emailTemplates.StatStartValue = this.numberFormatter(data.StatStartValue);
				this.$vs.loading.close()
      }).catch(e => {
        this.$vs.loading.close()
        this.showError(e);
      });
    },
    //Avg Time to Open
    getEmailAvgTimeOpenStat(){
      let getEmailAvgTimeOpenStatParams = {
        Date_End: this.toDate,
        Date_Start: this.fromDate
      }
      this.$vs.loading()
      this.axios.post("/ws/EmailTrackDashboard/GetEmailAvgTimeOpenStat", getEmailAvgTimeOpenStatParams).then(response => {
        let data = response.data;
        
        this.avgTimeToOpen.StatName = data.StatName;
				this.avgTimeToOpen.StatChangePercent = data.StatChangePercent;
				this.avgTimeToOpen.StatEndValue = this.numberFormatter(data.StatEndValue);
				this.avgTimeToOpen.StatStartValue = this.numberFormatter(data.StatStartValue);
				this.$vs.loading.close()
      }).catch(e => {
        this.$vs.loading.close()
        this.showError(e);
      });
    },
    getMostOpenedEmails(){
      let getMostOpenedEmailsParams = {
        Date_End: this.toDate,
        Date_Start: this.fromDate
      }
      this.$vs.loading()
      this.axios.post("/ws/EmailTrackDashboard/GetMostOpenedEmails", getMostOpenedEmailsParams).then(response => {
        let data = response.data;
        
        this.mostOpenedEmails = data
				this.$vs.loading.close()
      }).catch(e => {
        this.$vs.loading.close()
        this.showError(e);
      });
    },
    getEmailDetailByEmailId(id){
      this.$vs.loading()
      this.axios.get("/ws/EmailTrackActivityStream/GetEmailDetailByEmailId?id="+id).then(response => {
        let data = response.data;
        this.emailDetailsData = data
        this.emailDetailsPopupActive = true
        this.$vs.loading.close()
      }).catch(e => {
        this.$vs.loading.close()
        this.showError(e);
      });  
    },
    closeViewEmailData(){
      this.emailDetailsPopupActive = false
      this.emailDetailsData = {}
      setTimeout(() => {
        this.isEmailDetailsData = false
      }, 1000);
    },
    getMessagesSentMessagesOpenedDay(){
      let getMessagesSentMessagesOpenedDayParams = {
        Date_End: this.toDate,
        Date_Start: this.fromDate
      };
      this.$vs.loading()
      this.axios.post("/ws/EmailTrackDashboard/GetMessagesSentMessagesOpenedDay", getMessagesSentMessagesOpenedDayParams).then(response => {
        let data = response.data;
        let x = [];
        let FirstGroup = [];
        let SecondGroup = [];

        data.forEach(data => {
            // let dd =new Date(data.x);
            // let year = dd.getFullYear()
            // let month = dd.getMonth() + 1;
            // let day = dd.getDate();
            // let date = month + "/" + day + "/" + year;
            // x.push(date);
            x.push(data.x);
            
            FirstGroup.push(data.FirstGroup);
            SecondGroup.push(data.SecondGroup);
        });

        this.messageOverview.series = [
          {
            name: 'Messages Sent',
            type: 'column',
            data: FirstGroup
          },
          {
            name: 'Messages Opened',
            type: 'area',
            data: SecondGroup,
          },
        ];
        
        this.messageOverview.chartOptions = {...this.messageOverview.chartOptions, ...{
          xaxis: {
            type: 'datetime',
            categories: x,
            labels: {
              hideOverlappingLabels: true,
              showDuplicates: false,
              format: 'M/d',
            }
          }
        }}
        this.$vs.loading.close()
      }).catch(e => {
        this.$vs.loading.close()
        this.showError(e);
      });
    },
    getMessageAvgOpenTimeOpenedDay(){
      let getMessageAvgOpenTimeOpenedDayParams = {
        Date_End: this.toDate,
        Date_Start: this.fromDate
      };
      this.$vs.loading()
      this.axios.post("/ws/EmailTrackDashboard/GetMessageAvgOpenTimeOpenedDay", getMessageAvgOpenTimeOpenedDayParams).then(response => {
        let data = response.data;
        let x = [];
        let FirstGroup = [];

        data.forEach(data => {
            // let dd =new Date(data.x);
            // let year = dd.getFullYear()
            // let month = dd.getMonth() + 1;
            // let day = dd.getDate();
            // let date = month + "/" + day + "/" + year;
            // x.push(date);
            x.push(data.x);
            FirstGroup.push(data.FirstGroup);
        });

        this.dailyAverageTimeToOpenMinutes.series = [
          {
            name: 'Avg Time To Open in Minutes',
            data: FirstGroup,
          },
        ];
        
        this.dailyAverageTimeToOpenMinutes.chartOptions = {...this.dailyAverageTimeToOpenMinutes.chartOptions, ...{
          xaxis: {
            type: 'datetime',
            categories: x,
            labels: {
              hideOverlappingLabels: true,
              showDuplicates: false,
              format: 'M/d',
            }
          }
        }}
        this.$vs.loading.close()
      }).catch(e => {
        this.$vs.loading.close()
        this.showError(e);
      });
    },
  }
};
</script>
